import { Flex, Grid, useColorModeValue, useColorMode } from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import config from 'configs/app';
import useApiQuery from 'lib/api/useApiQuery';
import { WEI } from 'lib/consts';
import { HOMEPAGE_STATS } from 'stubs/stats';
import GasInfoTooltip from 'ui/shared/gas/GasInfoTooltip';
import GasPrice from 'ui/shared/gas/GasPrice';
import IconSvg from 'ui/shared/IconSvg';
import type { Props as StatsWidgetProps } from 'ui/shared/stats/StatsWidget';
import StatsWidget from 'ui/shared/stats/StatsWidget';
import blockIcon from 'icons/block.svg';
import clockIcon from 'icons/clock-light.svg';
import cycleWhiteIcon from 'icons/cycle-white.svg';
import cycleIcon from 'icons/cycle.svg';
import clockWhiteValidators from 'icons/validators-white.svg';
import clockValidators from 'icons/validators.svg';
import { secondsToDhms, calcCycleLength, calcCycleEndPercent } from 'lib/fuse_utils';
import { getTotalStaked, getActiveValidators, getCurrentCycleBlocks, getCycleEnd } from 'lib/consensus';
import StatsWidget2 from 'ui/shared/stats/StatsWidget2';

const hasAvgBlockTime = config.UI.homepage.showAvgBlockTime;
const rollupFeature = config.features.rollup;

const Stats = () => {
  const [ hasGasTracker, setHasGasTracker ] = React.useState(config.features.gasTracker.isEnabled);
  const { data, isPlaceholderData, isError, dataUpdatedAt } = useApiQuery('stats', {
    queryOptions: {
      refetchOnMount: false,
      placeholderData: HOMEPAGE_STATS,
    },
  });
  const [ totalStaked, setTotalStaked ] = useState(0);
  const [ totalvalidator, setTotalValidator ] = useState(0);
  const [ currentCycleEndBlock, setCurrentCycleEndBlocks ] = useState(0);
  const [ currentCycleStartBlocks, setCurrentCycleStaerBlocks ] = useState(0);
  const [ cycleEnd, setCycleEnd ] = useState('');
  const [ cyclePercent, setCyclePercent ] = useState(0);
  const { colorMode } = useColorMode();

  const fetchCycle = useCallback(async function() {
    try {
      const [ cycleStartBlock, cycleEndBlock ] = await getCurrentCycleBlocks();
      setCurrentCycleEndBlocks(Number(cycleEndBlock));
      setCurrentCycleStaerBlocks(Number(cycleStartBlock));

      const cycleLength = calcCycleLength(Number(cycleStartBlock), Number(cycleEndBlock));
      const cycleEnd = await getCycleEnd();
      const cyclePercent = calcCycleEndPercent(cycleEnd, cycleLength).toFixed(2);
      if (cyclePercent !== '') {
        setCyclePercent(Number(cyclePercent));
      }

      const totalStaked = await getTotalStaked();
      if (totalStaked > 0) {
        setTotalStaked(totalStaked);
      }

      const totalValidator  = await getActiveValidators();
      if (Number(totalValidator) > 0) {
        setTotalValidator(Number(totalValidator));
      }
    } catch (err) {
      // console.error('fetchCycle', err);
    }
  }, []);

  const handleIntervalChange = useCallback(async function() {
    try {
      let time: number;
      const [ cycleStartBlock, cycleEndBlock ] = await getCurrentCycleBlocks();
      let cycleEndInSeconds = await getCycleEnd();
      const cycleLength = calcCycleLength(Number(cycleStartBlock), Number(cycleEndBlock));
      function updateCycleTime() {
        if (!time) {
          time = cycleEndInSeconds;
          appendTime(time);
        } else {
          if (cycleEndInSeconds > 0) {
            time = --cycleEndInSeconds;
            // console.log('time 2: ', time);
            appendTime(time);
          } else {
            // when cycle is done, begin cycle from beginning
            cycleEndInSeconds = cycleLength;
            time = cycleEndInSeconds;
            // console.log('time 3: ', time);
            appendTime(time);
          }
        }
      }

      setInterval(updateCycleTime, 1000);
    } catch (err) {
      // console.error('handleIntervalChange', err);
    }
  }, []);

  function appendTime(time: number) {
    setCycleEnd(secondsToDhms(time));
  }
  React.useEffect(() => {
    if (!isPlaceholderData && !data?.gas_prices?.average) {
      setHasGasTracker(false);
    }
  // should run only after initial fetch
  // eslint-disable-next-line react-hooks/exhaustive-deps
  fetchCycle();
    handleIntervalChange();
  }, [ isPlaceholderData, fetchCycle, handleIntervalChange ]);

  const zkEvmLatestBatchQuery = useApiQuery('homepage_zkevm_latest_batch', {
    queryOptions: {
      placeholderData: 12345,
      enabled: rollupFeature.isEnabled && rollupFeature.type === 'zkEvm',
    },
  });

  const zkSyncLatestBatchQuery = useApiQuery('homepage_zksync_latest_batch', {
    queryOptions: {
      placeholderData: 12345,
      enabled: rollupFeature.isEnabled && rollupFeature.type === 'zkSync',
    },
  });

  const arbitrumLatestBatchQuery = useApiQuery('homepage_arbitrum_latest_batch', {
    queryOptions: {
      placeholderData: 12345,
      enabled: rollupFeature.isEnabled && rollupFeature.type === 'arbitrum',
    },
  });

  if (isError || zkEvmLatestBatchQuery.isError || zkSyncLatestBatchQuery.isError || arbitrumLatestBatchQuery.isError) {
    return null;
  }

  const isLoading = isPlaceholderData ||
    (rollupFeature.isEnabled && rollupFeature.type === 'zkEvm' && zkEvmLatestBatchQuery.isPlaceholderData) ||
    (rollupFeature.isEnabled && rollupFeature.type === 'zkSync' && zkSyncLatestBatchQuery.isPlaceholderData) ||
    (rollupFeature.isEnabled && rollupFeature.type === 'arbitrum' && arbitrumLatestBatchQuery.isPlaceholderData);

  const content = (() => {
    if (!data) {
      return null;
    }
    const gasInfoTooltip = hasGasTracker && data.gas_prices && data.gas_prices.average ? (
      <GasInfoTooltip data={ data } dataUpdatedAt={ dataUpdatedAt }>
        <IconSvg
          isLoading={ isLoading }
          name="info"
          boxSize={ 5 }
          flexShrink={ 0 }
          cursor="pointer"
          color="icon_info"
          _hover={{ color: 'link_hovered' }}
        />
      </GasInfoTooltip>
    ) : null;

    const hasBatches = rollupFeature.isEnabled && (rollupFeature.type === 'zkEvm' || rollupFeature.type === 'zkSync' || rollupFeature.type === 'arbitrum');
    const latestBatch =
      (hasBatches && rollupFeature.type === 'zkEvm' ? zkEvmLatestBatchQuery.data : null) ||
      (hasBatches && rollupFeature.type === 'zkSync' ? zkSyncLatestBatchQuery.data : null) ||
      (hasBatches && rollupFeature.type === 'arbitrum' ? arbitrumLatestBatchQuery.data : null) || 0;

    const items: Array<StatsWidgetProps> = [
      hasBatches && {
        icon: 'txn_batches_slim' as const,
        label: 'Latest batch',
        value: latestBatch.toLocaleString(),
        href: { pathname: '/batches' as const },
        isLoading,
      },
      !hasBatches && {
        icon: 'block_slim' as const,
        label: 'Total blocks',
        value: Number(data.total_blocks).toLocaleString(),
        href: { pathname: '/blocks' as const },
        isLoading,
      },
      hasAvgBlockTime && {
        icon: 'clock-light' as const,
        label: 'Average block time',
        value: `${ (data.average_block_time / 1000).toFixed(1) }s`,
        isLoading,
      },
      {
        icon: 'transactions_slim' as const,
        label: 'Total transactions',
        value: Number(data.total_transactions).toLocaleString(),
        href: { pathname: '/txs' as const },
        isLoading,
      },
      rollupFeature.isEnabled && data.last_output_root_size && {
        icon: 'txn_batches_slim' as const,
        label: 'Latest L1 state batch',
        value: data.last_output_root_size,
        href: { pathname: '/batches' as const },
        isLoading,
      },
      {
        icon: 'wallet' as const,
        label: 'Wallet addresses',
        value: Number(data.total_addresses).toLocaleString(),
        isLoading,
      },
      hasGasTracker && data.gas_prices && {
        icon: 'gas' as const,
        label: 'Gas tracker',
        value: data.gas_prices.average ? <GasPrice data={ data.gas_prices.average }/> : 'N/A',
        hint: gasInfoTooltip,
        isLoading,
      },
      data.rootstock_locked_btc && {
        icon: 'coins/bitcoin' as const,
        label: 'BTC Locked in 2WP',
        value: `${ BigNumber(data.rootstock_locked_btc).div(WEI).dp(0).toFormat() } RBTC`,
        isLoading,
      },
      data.celo && {
        icon: 'hourglass' as const,
        label: 'Current epoch',
        value: `#${ data.celo.epoch_number }`,
        isLoading,
      },
    ].filter(Boolean);

    return (
      <>
        { items.map((item, index) => (
          <StatsWidget
            key={ item.icon }
            { ...item }
            isLoading={ isLoading }
            _last={ items.length % 2 === 1 && index === items.length - 1 ? { gridColumn: 'span 3' } : undefined }/>
        ),
        ) }
      </>
    );
  })();

  let itemsCount = 6;
  const symbol = config.chain.currency.symbol;
  const isOdd = Boolean(itemsCount % 3);
  const bgColor = useColorModeValue('gray.50', 'whiteAlpha.100');
  const lastItemTouchStyle = { gridColumn: { base: 'span 3', lg: 'unset' } };

  const content2 = (
    <>
      <StatsWidget2
        icon={ clockIcon }
        title="Total Staked"
        value={ `${ (totalStaked).toLocaleString() } ${ symbol }` }
      />
      <StatsWidget2
        icon={ colorMode === 'light' ? clockValidators : clockWhiteValidators }
        title="Active Validators"
        value={ `${ String(totalvalidator) } validators` }
        url="https://status.miexs.com/"
      />
      <StatsWidget2
        icon={ colorMode === 'light' ? cycleIcon : cycleWhiteIcon }
        title="Next Cycle"
        value={ cycleEnd }
      />
      <StatsWidget2
        icon={ blockIcon }
        title="Curent cycle blocks"
        value={ `${ (currentCycleStartBlocks).toLocaleString() } - ${ (currentCycleEndBlock).toLocaleString() }` }
        _last={ isOdd ? lastItemTouchStyle : undefined }
      />
      <Flex
        backgroundColor={ bgColor }
        padding={ 3 }
        borderRadius="md"
        flexDirection="row"
        alignItems="center"
        columnGap={ 3 }
        rowGap={ 2 }
        position="relative"
      >
        <div style={{ width: 86, height: 86, margin: '0 auto' }}>
          <CircularProgressbar
            value={ cyclePercent }
            text={ `${ cyclePercent * 100 }%` }
            maxValue={ 1 }
            styles={ buildStyles({
              rotation: 0,
              strokeLinecap: 'round',
              textSize: '16px',
              pathTransitionDuration: 0.5,
              pathColor: `rgba(62, 152, 199, ${ cyclePercent })`,
              textColor: useColorModeValue('#1A202C', 'white'),
              trailColor: '#d6d6d6',
              backgroundColor: '#F7FAFC',
            }) }
          />
        </div>
      </Flex>
    </>
  );

  return (
    <Grid
      gridTemplateColumns="1fr 1fr 1fr"
      gridGap={{ base: 1, lg: 2 }}
      flexBasis="50%"
      flexGrow={ 1 }
    >
      { content }
      { content2 }
    </Grid>

  );
};

export default Stats;
